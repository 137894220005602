<template>
  <div>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    autocomplete="off"
  >
  <v-container>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Control de trazabilidad
    </div>

    <!-- <v-row dense no-gutters class="mt-3">
      <v-col cols="6" sm="5" md="3" lg="3" xl="2">
        <CtrlCatFind class="mb-n3" ref="Ctrl" label="Tipo" :CtrlCatIdParent="1" v-model="tipo" :readonly="true" @change="ctrlCatChange" :selectOnReadonly="true"></CtrlCatFind>
      </v-col>
    </v-row> -->

  <v-row dense>
    <v-col class="mb-n5" cols="7" sm="3" md="3" lg="2" xl="2">
      <DateFind class="mb-n3" label="Fecha" v-model="fecha" @change="FechaCambiada"></DateFind>
    </v-col>

    <v-col cols="4" sm="2" md="2" lg="2" xl="2">  
      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="0"
        :return-value.sync="hora"
        transition="scale-transition"
        offset-y
        style="width: 400px"
        allow-overflow
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="CurrentEntity.TrazHora"
            label="Hora"
            append-icon="mdi-clock-outline"
            class="mr-2"
            outlined
            dense
            readonly
            v-on="on"
            @change="HoraCambiada"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="CurrentEntity.TrazHora"
          format="ampm"
          @click:minute="$refs.menu.save(hora)"
          @change="HoraPickerCambiada"
        ></v-time-picker>
        <!-- Quitado full-width para que no se deforme el time-picker -->
      </v-menu>
    </v-col>

    <v-col cols="12" sm="7" md="6" lg="4" xl="3">
      <!-- <ArtCatFind class="mb-n3" ref="artCatCtrl" label="Tipo" artCatIdParent="1" v-model="tipo" :readonly="true" :disabled="CurrentEntity.TrazId != null && CurrentEntity.TrazId != 0" @change="artCatChange" ></ArtCatFind> -->
      <!-- :disabled="CurrentEntity.TrazId != null && CurrentEntity.TrazId != 0"  -->
      <ArtCatFind class="mb-n3 mr-1" ref="artCatCtrl" label="Tipo" artCatIdParent="1" v-model="artCat" @change="artCatChange"></ArtCatFind>
    </v-col>

    <!-- Filtro de articulo -->
    <v-col cols="12" sm="7" md="6" lg="4" xl="3">
      <ArtFind
          class="mb-n3" id="artCtrl" ref="artCtrl"
          v-model="art"
          label="Buscar artículo"
          includeArtCat
          @change="artChange">
          <!-- :readonly="artCat == null || artCat.ArtCatId == null" -->
      </ArtFind>
    </v-col>

    <!-- Activar/Desactivar filtro de categoria -->
    <v-col cols="12" sm="5" md="5" lg="3" xl="2" v-if="false">
      <v-switch class="mt-0 pt-0 pb-1"
        v-model="useArtCatFilter"
        :label="'Filtrar por tipo'"
        hide-details
        style="display: inline-block"
      ></v-switch>
    </v-col>

    <!-- <v-col cols = 1>
      <v-text-field v-model="filtro_tabla"></v-text-field>
    </v-col> -->

    <template v-if="false">
      <v-col cols="12" sm="12" md="6" lg="6" xl="5">
        <!-- <PerFind class="mb-n3" label="Empleado" :perCatIdAnyLevel="1" v-model="empleado"></PerFind> -->
        <PerFind class="mb-n3" label="Empleado" :perCatIdAnyLevel="1" v-model="CurrentEntity._Per"></PerFind>
      </v-col>        
    </template>
  </v-row>

  <v-row dense>
    <v-col>
      <v-data-table
        dense
        ref="tableTraz"
        :headers="headers"
        :items="itemsTraz"
        :items-per-page="-1"
        disable-pagination
        hide-default-footer
        style="overflow: scroll"
        mobile-breakpoint="0"
        :search="artCatIdStr"
        :custom-filter="DataFilter"
        :item-class="selectedArt"
        class="mb-5 elevation-1 traz-table"
        >

        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.TrazArtCant="{ item }">
          <v-text-field dense reverse v-model="item.TrazArtCant" :background-color="background_color_cant(item)" type="number" class="pt-0 pb-1 mb-n7" solo flat @change="CantidadCambiada"></v-text-field>
        </template>

        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.TrazArtLote="{ item }">
          <v-text-field dense v-model="item.TrazArtLote" :background-color="background_color_lote(item)" type="text" class="pt-0 pb-1 mb-n7" solo flat @change="LoteCambiado"></v-text-field>
        </template>

        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.TrazArtCad="{ item }">
          <v-text-field dense v-model="item.TrazArtCad" :background-color="background_color_cad(item)" type="date" class="pt-0 pb-1 mb-n7" solo flat @change="CaducidadCambiada"></v-text-field>
        </template>

      </v-data-table>          
    </v-col>
  </v-row>

  <v-row dense>
    <v-textarea
      class="mb-n5"
      outlined
      name="obsCtrl"
      label="Observaciones"
      v-model="CurrentEntity.TrazObs"
      @change="ObservacionesCambiadas"
    ></v-textarea>
      <!-- v-model="obs" -->
  </v-row>

    <v-row style="height:60px" no-gutters>
      <v-col>
      <div class="text-center">
          <!-- <v-btn
            color="normal"
            class="mt-4 mr-2"
            @click="grabar"
            style="width:100px"
            ref="aceptarBtn"
            >
            Aceptar
          </v-btn>

          <v-btn
            color="normal"
            class="mt-4"
            @click="cancelar"
            style="width:100px"
            ref="cancelarBtn"
            >
              Cancelar
          </v-btn> -->
          <v-btn
            color="normal"
            class="mt-4"
            :loading="saving"
            @click="salir"
            style="width:100px"
            ref="cancelarBtn"
            >
              Salir
          </v-btn>

      </div>
      </v-col>
    </v-row>
  </v-container>
  </v-form>

  <!-- Modal Artículo no encontrado -->
  <v-dialog
    v-model="showAddTrazArtDialog"
    transition="dialog-bottom-transition"
    persistent
    width="500"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" style="word-break: break-word; overflow-wrap: break-word;">
        ARTÍCULO NO ENCONTRADO
      </v-card-title>

      <v-card-text class="mt-2">
        El artículo seleccionado no está en la lista. ¿Desea añadirlo?
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="addTrazArt"
        >
          Aceptar
        </v-btn>
        <v-btn
          @click="cancelAddTrazArt"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </div>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  import { mapActions } from "vuex";
  import router from "../router";
  import ArtFind from "./ArtFind.vue";
  import ArtCatFind from "./ArtCatFind.vue";
  import DateFind from "./DateFind.vue";
  import PerFind from "./PerFind.vue";
  // import PerSel from "./PerSel.vue";

  export default {
    components: {
       ArtFind,
       ArtCatFind,
       DateFind,
       PerFind,
      //  PerSel
     },
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz']),
        ...mapState('Traz', ['CurrentEntity'])
    },
    data: () => ({
      saving: false,
      pendingChanges: false,
      hora: null, // Variable para que no muestre error v-time-picker
      artCatIdStr: '',
      useArtCatFilter: true, // Indica si se usa o no el filtro por categoria
      valid: true,
      fecha: '',
      artCat: {},
      art: {}, // Control del filtro de articulo
      empleado: {},
      obs: '',
      menu2: false,
      itemsTraz: [],
      headers: [
        { text: 'ARTICULO', value: '_Art.ArtNom', class: 'col_maq' },
        { text: 'CANTIDAD', value: 'TrazArtCant', width: '140' },
        { text: 'LOTE', value: 'TrazArtLote', align: 'center', width: '220' },
        { text: 'CADUCIDAD', value: 'TrazArtCad', align: 'center', width: '220' },
      ],
      ArtCat: {},
      showAddTrazArtDialog: false // Dialogo de añadir artículo
    }),
    // watch: {
    // },
    methods: {
      ...mapActions('Traz', ['GetArtCatEntities']),
      validar () {
        this.$refs.form.validate()
        var ValRet = this.valid

        // if (this.valid) {
        //   if (this.paraUsuario.length == 0 && this.paraGrupo.length == 0) {
        //     alert("Debe haber al menos un destinatario del mensaje")
        //     ValRet = false;
        //   }
        //   else {
        //     if (this.textoMensaje.length == 0) {
        //       alert("El texto no puede estar vacío");
        //       ValRet = false;
        //     }
        //   }
        // }
        return ValRet;
      },   
      FechaCambiada() {
        this.grabar();
      },
      CantidadCambiada() {
        this.grabar();
      },  
      LoteCambiado() {
        this.grabar();
      },  
      CaducidadCambiada() {
        this.grabar();
      },  
      ObservacionesCambiadas() {
        this.grabar();
      },
      HoraCambiada() {
        this.grabar();
      },
      HoraPickerCambiada() {
        this.grabar();
      },
      DataFilter(value, search, item) {
        //#region Filtro por categoria y producto - Filtro nuevo
        // Comprobamos si hay que filtrar por articulo - Desactivado filtro de artículo
        let checkArt = true;
        // if (this.art != null && this.art.ArtId != null) {
        //   checkArt = this.art.ArtId == item.ArtId;
        // }

        // Comprobamos si hay que filtrar por categoría
        let checkArtCat = true;
        if (this.useArtCatFilter && this.artCat != null && this.artCat.ArtCatId != null) {
            var ArtCatIdFilter = parseInt(search);
            checkArtCat = item._Art.ArtCatArt.some(aca=> aca.ArtCatId === ArtCatIdFilter);
        }
        
        return checkArt && checkArtCat;
        //#endregion

        //#region Filtro solo por categoria - Filtro antiguo
        // var ArtCatIdFilter = parseInt(search);
        // return item._Art.ArtCatArt.some(aca=>aca.ArtCatId === ArtCatIdFilter);
        //#endregion

        // this.itemsTraz = this.CurrentEntity.TrazArt.filter(item=>item._Art.ArtCatArt.some(aca=>aca.ArtCatId === ArtCatIdFilter))
        // this.itemsTraz = this.itemsTraz.filter(item=>item.ArtId === 14754)
        // return items.filter(item=>item.ArtId === 14754)
      },
      grabar () {
        if (this.saving) {
          this.pendingChanges = true;
          return;
        }

        if (this.validar())
        {
          this.CurrentEntity.TrazTmp = this.fecha + " " + this.CurrentEntity.TrazHora;
          this.CurrentEntity.ArtCatId = this.artCat != null ? this.artCat.ArtCatId : null;
          this.CurrentEntity.PerId = this.CurrentEntity._Per.PerId;
          // this.CurrentEntity.ArtCat = this.tipo;
          // this.CurrentEntity.PerId = this.empleado.PerId;
          // this.CurrentEntity.Per = this.empleado;
          // this.CurrentEntity.TrazObs = this.obs;

          // this.itemsTraz.forEach(element => {
          //   delete element.Art;
          // });
          this.CurrentEntity.TrazArt = this.itemsTraz;

          this.saving = true;

          var controllerParameters = {
              Action: 'SAVE_DATA',
              Traz: this.CurrentEntity
          };

          var AuthToken = localStorage.getItem('token');
          // axios({ method: "PUT", "url": this.urlRaiz + "/api/mensajes", "data": entidadJson, "headers": { "content-type": "application/json" } })
          axios({ method: "POST", "url": this.urlRaiz + "/api/traz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
              this.saving = false;
              
              if (result.status >= 200 && result.status <= 299) {
                var TrazSaved = result.data;
                this.CurrentEntity.TrazId = TrazSaved.TrazId;
                
                var i = 0;
                this.itemsTraz.forEach(element => {
                  element.TrazId = TrazSaved.TrazId;
                  element.TrazArtId = TrazSaved.TrazArt[i].TrazArtId
                  i++;
                });

                i = 0;
                this.CurrentEntity.TrazArtCat.forEach(element => {
                  element.TrazId = TrazSaved.TrazId;
                  element.TrazArtCatId = TrazSaved.TrazArtCat[i].TrazArtCatId
                  i++;
                });

                // Comprobamos si quedan cambios pendientes por guardar
                if (this.pendingChanges) {
                  this.pendingChanges = false;
                  this.grabar();
                }

                //  alert('Datos grabados con éxito');
                //  router.push('/control-trazabilidad2');
              }
            })
            .catch(error => {
              this.saving = false;
              alert(error);
            })
        }
      },
    cancelar() {
      router.push('/control-trazabilidad2');
    },
    salir() {
      router.push('/control-trazabilidad2');
    },
    artCatChange() {
      if (this.artCat == null) {
        // this.artCatIdStr = '0'; // 0 para filtro de articulo
        this.artCatIdStr = '';
        return; 
      }

      this.CurrentEntity.TrazTmp = this.fecha + ' ' + this.CurrentEntity.TrazHora;
      var TrazArtCatFound = this.CurrentEntity.TrazArtCat.find(f=>f.ArtCatId == this.artCat.ArtCatId);
      if (TrazArtCatFound == undefined || TrazArtCatFound == null) {

        var TrazArtCatObj = {TrazArtCatId: 0, TrazId: this.CurrentEntity.TrazId, ArtCatId: this.artCat.ArtCatId, 
          _ArtCat: this.artCat };

        this.CurrentEntity.TrazArtCat.push(TrazArtCatObj);
      }

      this.GetArtCatEntities({EmpId: this.empId, TrazTmp: this.CurrentEntity.TrazTmp, ArtCatId: this.artCat.ArtCatId, DirId: this.$store.state.dir.DirId, CopTrazAnt: this.$store.state.emp.CopTrazAnt})
        .then(result=> {
          // this.itemsTraz = result;
          result.forEach(element => {
            var itemFound = this.itemsTraz.find(elemItemsTraz=> elemItemsTraz.ArtId == element.ArtId)
            if (itemFound == undefined || itemFound == null) {
              this.itemsTraz.push(element);
            }
          });
        })

      this.artCatIdStr = this.artCat.ArtCatId.toString();
      // this.DataFilter();



      // var controllerParameters = {
      //     Action: 'NEW_TRAZ_ART',
      //     EmpId: this.empId,
      //     TrazTmp: this.fecha,
      //     ArtCatId: this.tipo.ArtCatId,
      //     DirId: this.$store.state.dir.DirId,
      //     CopTrazAnt: this.$store.state.emp.CopTrazAnt
      // };

      // var AuthToken = localStorage.getItem('token');
      // axios({ method: "POST", "url": this.urlRaiz + "/api/traz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
      //     this.itemsTraz = result.data;

      //     this.itemsTraz.forEach(element => {
      //       element.TrazArtCad = element.TrazArtCad.substr(0, 10);
      //     });

      // });            
    },
    // Control del cambio en el filtro de articulo
    artChange() {
      if (this.art == null) {
        return;
      }
      
      const filteredArts = this.itemsTraz.filter(f => f.ArtId == this.art.ArtId);
      // Si no está en la lista se muestra el dialogo para añadir el articulo
      if (filteredArts.length == 0) {
        // alert('Artículo no encontrado');
        // this.art = null;
        this.showAddTrazArtDialog = true;
      } else {
        // Actualizamos el filtro de categoría
        if (this.art.ArtCatArt.length == 0) {
          this.artCat = null;
          this.artCatIdStr = '';
          this.scrollToSelected();
          return;
        }

        if (this.artCat != null) {
          const hasCat = this.art.ArtCatArt.some(aca=> aca.ArtCatId === this.artCat.ArtCatId);
          if (hasCat) { 
            this.scrollToSelected();
            return; 
          }
        }

        this.artCat = {
          ArtCatId: this.art.ArtCatArt[0].ArtCat.ArtCatId,
          ArtCatNom: this.art.ArtCatArt[0].ArtCat.ArtCatNom
        };
        this.artCatIdStr = this.artCat.ArtCatId.toString();

        this.scrollToSelected();
      }
    },
    scrollToSelected () {
      if (this.art == null) { return; }

      // Obtenemos los datos de la tabla
      const table = this.$el.getElementsByClassName('traz-table');
      if(table.length == 0) { return; }

      const tableTop = table[0].getBoundingClientRect().top;
      const headerSize = 32;
      const itemSize = 41;

      // Filtro por categoría
      const items = this.itemsTraz.filter(f => this.artCat == null || f._Art.ArtCatArt.some(aca=> aca.ArtCatId === this.artCat.ArtCatId));
      const index = items.findIndex(f => f.ArtId == this.art.ArtId);

      if (index == -1) { return; }
      
      // Calculamos la altura del scroll
      const yOffset = -80;
      const y = tableTop + headerSize + (itemSize * index) + window.pageYOffset + yOffset;
      
      window.scrollTo({top: y, behavior: 'smooth'});
    },
    // Añade un artículo a la lista
    addTrazArt() {
      // Creamos el nuevo articulo
      const newTrazArt = {
        ArtId: this.art.ArtId,
        TrazArtCad: null,
        TrazArtCant: null,
        TrazArtLote: null,
        TrazId: this.CurrentEntity.TrazId,
        _Art: this.art
      };

      // Mapeamos los datos de ArtCatArt
      const newArtCatArts = this.art.ArtCatArt.map(m => {
        return {
          ArtCatArtId: m.ArtCatArtId,
          ArtId: m.ArtId,
          ArtCatId: m.ArtCatId,
          ArtCat: {
            ArtCatId: m.ArtCat.ArtCatId,
            ArtCatNom: m.ArtCat.ArtCatNom
          }
        }
      });
      newTrazArt._Art.ArtCatArt = newArtCatArts;

      // Añadimos el articulo a la lista
      this.itemsTraz.push(newTrazArt);
      
      //#region Añadir las categorias a TrazArtCat - Desactivado
      // // Mapeamos las categorias
      // const newArtCats = this.art.ArtCatArt.map(m => {
      //   return {
      //     ArtCatId: m.ArtCat.ArtCatId,
      //     ArtCatNom: m.ArtCat.ArtCatNom
      //   }
      // });

      // // Añadimos las categorías a TrazArtCat
      // newArtCats.forEach(artCat => {
      //   const TrazArtCatFound = this.CurrentEntity.TrazArtCat.find(f=>f.ArtCatId == artCat.ArtCatId);
      //   if (TrazArtCatFound == null) {
      //     const TrazArtCatObj = {
      //       TrazArtCatId: 0, 
      //       TrazId: this.CurrentEntity.TrazId, 
      //       ArtCatId: artCat.ArtCatId, 
      //       _ArtCat: artCat 
      //     };

      //     this.CurrentEntity.TrazArtCat.push(TrazArtCatObj);
      //   }
      // });

      // Actualizamos el filtro de categoría - con TrazArtCat añadido
      // if (newArtCats.length > 0) {
      //   this.artCat = newArtCats[0];
      //   this.artCatIdStr = this.artCat.ArtCatId.toString();
      // } else {
      //   this.artCat = {};
      //   // Se asigna a 0 en vez de vacío para que aplique el filtro de articulo
      //   this.artCatIdStr = '0';
      // }
      //#endregion

      // Actulizamos el filtro de categoría
      this.artCat = {};
      // Se asigna a 0 en vez de vacío para que aplique el filtro de articulo
      // this.artCatIdStr = '0';  // 0 para filtro de articulo
      this.artCatIdStr = '';

      // Cerramos el dialogo y guardamos
      this.showAddTrazArtDialog = false;
      this.grabar();
    },
    // Cancela el añadido de artículo y limpia el filtro
    cancelAddTrazArt() {
      this.showAddTrazArtDialog = false;
      this.art = {};
    },
    background_color_cant(itm) {
      if (((itm.TrazArtLote != null && itm.TrazArtLote != '') || (itm.TrazArtCad != null && itm.TrazArtCad != '')) && (itm.TrazArtCant == null || itm.TrazArtCant <= 0)) {
        return 'red lighten-4';
      }
      else if ((itm.TrazArtCant != null && itm.TrazArtCant > 0) && (itm.TrazArtLote != null && itm.TrazArtLote != '') && (itm.TrazArtCad != null && itm.TrazArtCad != '')) {
        return 'green lighten-4';
      }
    },
    background_color_lote(itm) {
      if (((itm.TrazArtCant != null && itm.TrazArtCant > 0) || (itm.TrazArtCad != null && itm.TrazArtCad != '')) && (itm.TrazArtLote == null || itm.TrazArtLote == '')) {
        return 'red lighten-4';
      }
      else if ((itm.TrazArtCant != null && itm.TrazArtCant > 0) && (itm.TrazArtLote != null && itm.TrazArtLote != '') && (itm.TrazArtCad != null && itm.TrazArtCad != '')) {
        return 'green lighten-4';
      }
    },
    background_color_cad(itm) {
      if (((itm.TrazArtCant != null && itm.TrazArtCant > 0) || (itm.TrazArtLote != null && itm.TrazArtLote != '')) && (itm.TrazArtCad == null || itm.TrazArtCad == '')) {
        return 'red lighten-4';
      }
      else if ((itm.TrazArtCant != null && itm.TrazArtCant > 0) && (itm.TrazArtLote != null && itm.TrazArtLote != '') && (itm.TrazArtCad != null && itm.TrazArtCad != '')) {
        return 'green lighten-4';
      }
    },
    selectedArt(item) {
      if(this.art == null) { return ''; }
      if (item.ArtId === this.art.ArtId) { return 'selected-art'; } 
      return '';
    }
  },
  mounted() {
    this.fecha = this.CurrentEntity.TrazFecha;
    this.artCat = this.CurrentEntity._ArtCat;
    this.artCatIdStr = this.artCat != null ? this.artCat.ArtCatId.toString() : '0';

    // this.fecha = this.CurrentEntity.TrazTmp.substr(0, 10);
    // if (this.fecha == null || this.fecha == '') {
    //   this.fecha
    // }
    // this.tipo = this.CurrentEntity._ArtCat;
    // this.empleado = this.CurrentEntity.Per;
    // this.obs = this.CurrentEntity.TrazObs;

    this.itemsTraz = this.CurrentEntity.TrazArt;

    // this.itemsTraz.forEach(element => {
    //   element.TrazArtCad = element.TrazArtCad.substr(0, 10);
    // });
  }
}
</script>

<style>
  .traz-table .selected-art {
      background-color: #AECAFF;
  }
  .traz-table .selected-art:hover {
      background-color: #A3C3FF !important;
  }
</style>
